<template>
	<div class="index">
		<Nav></Nav>
		<div class="index-place-holder"></div>
		<div class="index-me-cover">
			<div class="index-me-avatar"><img v-lazy="'/img/kasuie.webp'" width="11em" alt="avatar"></div>
			<div class="index-me-name"><span>KASUIE</span></div>
			<div><input type="search" name="search" id="words" placeholder="喵喵喵？" disabled autocomplete="off" /></div>
			<div class="index-down">
				<img src="/img/kasuie/down.png" width="64px">
			</div>
		</div>
		<Article></Article>
		<Tag></Tag>
	</div>
</template>

<script>
import Article from '@/components/Article.vue'
import Nav from '@/components/Nav.vue'
import Tag from '@/components/Tag.vue'

export default {
  metaInfo: {
      title: 'KASUIEの次元',
      meta: [
    	  {
    		  name: 'description',
              content: 'KASUIEの次元，兴趣至上，内容随缘',
    	  },
          {
              name: 'keywords',
              content: 'KASUIEの次元,KASUIE的个人博客,KASUIE,个人博客'
          }
    ]
  },
  name: 'Index',
  components: {
    Article,
	Nav,
	Tag
  },
  data() {
  	return {
		words: [],
	}
  },
  methods: {
	  getWords(){
		  let that = this
		  this.axios.post('words').then(function(results){
			  that.words = results.data
			  that.write()
		  },function(err){
			  console.log(err);
		  })
	  },
	  write(){
		  let dom = document.getElementById('words');
		  let sentence = this.words;
		  let words = sentence[0].split('');
		  function getRandom(){
			  let s = Math.floor(Math.random()*(sentence.length));
			  words = sentence[s].split('');
	      }
	      let index = 0;
		  let flag = true;
		  let text = "";
		  writing(index);
		  function writing(index) {
			  if ((index < words.length)&&(flag)) {
				  text = text + words[index];
				  dom.value = text;
				  setTimeout(writing.bind(this), 200, ++index);
	            } else{
					if(index==words.length){
						flag = false;
						setTimeout(writing.bind(this), 7000, --index);
	                }else if(index==0){
						flag = true;
						getRandom();
						dom.value = (dom.value.slice(0,-1));
						text = "";
						setTimeout(writing.bind(this), 3000,index);
					}else{
						input();
					}
	            }
	            function input(){
					dom.value = (dom.value.slice(0,-1));
					setTimeout(writing.bind(this), 50, --index);
					}
	            }
	    }
  },
  mounted() {
  	this.getWords()
	document.body.style.overflow='hidden'
	setTimeout(() => {
		this.$store.commit('showLoading')
		document.body.style.overflow=''
	},2000)
  },
  created() {
		document.title = 'KASUIEの次元'
		let title
		setTimeout(function() {
		   	title = document.title
		}, 1000);
		window.onfocus = function () {
			document.title = '恢复正常了...';
		    setTimeout(function() {
				document.title = title
			}, 1000);
		};
		window.onblur = function () {
			setTimeout(function() {
				document.title = '快回来~页面崩溃了'
			}, 5000);
		};
  },
  destroyed() {
  	this.$store.commit('loading')
  }
}
</script>

<style>
.index-place-holder{
	height: 25em;
}
.index-me-cover{
	width: 100%;
	margin-bottom: 10em;
}
.index-me-avatar img{
	width: 11em;
	border-radius: 6em;
	transition: all .5s ease-in-out;
	animation: light 4s ease-in-out infinite;
}
.index-me-avatar img:hover{
	position: relative;
	transform: translateY(-.75em)
}
.index-me-cover div:nth-child(2){
	font-size: 30px;
	color: #29bdff;
	font-family: STSong;
	text-shadow: 3px 3px 3px skyblue;
}
.index-down{
	margin-top: 15em;
}
.index-down img{
	width: 64px;
}
#words{
	width: 30%;
	height: 3em;
	font-size: 1em;
	text-align: center;
	outline-style: none;
	border-radius: 4em;
	margin-bottom: 1em;
	color: #446c7d;
	text-shadow: 2px 2px 1px skyblue;
	border: none;
	background-color: rgba(255,255,255,.25);
	transition: all 0.3s ease-in-out;
}
@keyframes light {
    0% {
        box-shadow: 0 0 4px #50afda;
		-moz-box-shadow: 0 0 4px #50afda;
		-webkit-box-shadow:0 0 4px #50afda;
    }
    25% {
		box-shadow: 0 0 30px #50afda;
		-moz-box-shadow: 0 0 30px #50afda;
		-webkit-box-shadow:0 0 30px #50afda;
    }
    50% {
        box-shadow: 0 0 4px #50afda;
        -moz-box-shadow: 0 0 4px #50afda;
        -webkit-box-shadow:0 0 4px #50afda;
    }
    75% {
        box-shadow: 0 0 30px #50afda;
        -moz-box-shadow: 0 0 30px #50afda;
        -webkit-box-shadow:0 0 30px #50afda;
    }
    100% {
        box-shadow: 0 0 4px #50afda;
        -moz-box-shadow: 0 0 4px #50afda;
        -webkit-box-shadow:0 0 4px #50afda;
    }
}
@media screen and (max-width: 770px) {
	#words{
		width: 80%;
	}
	.index-place-holder{
		height: 15em;
	}
	.index-me-cover div:nth-child(2){
		font-size: 26px;
	}
	.index-me-avatar img{
		width: 7em;
	}
	.index-down{
		margin-top: 13em;
	}
}
</style>
