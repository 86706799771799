<template>
	<div class="article-all">
		<ul>
			<h2 class="article-all-h2"><i class="el-icon-paperclip"></i>置顶文章<i class="el-icon-paperclip"></i></h2>
			<li class="article pin" v-for="(article,index) in Particles" :key='index'>
				<a href="javascript:void(0);" @click="goto(article.aid)"><div class="bg-img bg"><img v-lazy="article.topImg" alt="topImg"></div></a>
				<img src="/img/kasuie/pin.png" alt="pin" class="pin-img">
				<p class="article-type"><img src="/img/kasuie/type.png" alt="type"><span v-text="article.type"></span></p>
				<div class="article-content">
					<h2><a href="javascript:void(0);" @click="goto(article.aid)" v-text="article.title"></a></h2>
					<div class="line">
						<p v-text="article.description"></p>
						<hr style="text-align: center">
						<div class="data">
							<p><img src="/img/kasuie/time.png" alt="时间"><span v-text="$moment(article.createTime).format('YYYY-MM-DD')"></span><img src="/img/kasuie/comment.png" alt="评论数"><span v-text="article.countsComment"></span><img src="/img/kasuie/views.png" alt="浏览量"><span v-text="article.views"></span><img src="/img/kasuie/author.png" alt="作者"><span v-text="article.author"></span></p>
						</div>
				    </div>				
				</div>
			</li>
		</ul>
		<ul>
			<h2 class="article-all-h2"><i class="el-icon-paperclip"></i>所有文章<i class="el-icon-paperclip"></i></h2>
			<li class="article" v-for="(article,index) in tempArticle" :key='index'>
				<a href="javascript:void(0);" @click="goto(article.aid)"><div class="bg-img bg"><img v-lazy="article.topImg" alt="topImg"></div></a>
				<p class="article-type"><img src="/img/kasuie/type.png" alt="type"><span v-text="article.type"></span></p>
				<div class="article-content">
					<h2><a href="javascript:void(0);" @click="goto(article.aid)" v-text="article.title"></a></h2>
					<div class="line">
						<p v-text="article.description"></p>
						<hr style="text-align: center">
						<div class="data">
							<p><img src="/img/kasuie/time.png" alt="时间"><span v-text="$moment(article.createTime).format('YYYY-MM-DD')"></span><img src="/img/kasuie/comment.png" alt="评论数"><span v-text="article.countsComment"></span><img src="/img/kasuie/views.png" alt="浏览量"><span v-text="article.views"></span><img src="/img/kasuie/author.png" alt="作者"><span v-text="article.author"></span></p>
						</div>
				    </div>				
				</div>
			</li>
		</ul>
		<el-pagination
		  background
		  layout="prev, pager, next"
		  :hide-on-single-page="articlesA.length<=10"
		  :total="articlesA.length"
		  @current-change='handleCurrentChange'
		  class="article-pager">
		</el-pagination>
	</div>
</template>

<script>
export default {
  inject: ['loading','loaded'],
  name: 'Article',
  data() {
  	return {
		tempArticle: [],
  		articlesA: [],
		Particles: [],
		loading: true
  	}
  },
  watch: {
	  articlesA(){
		  for (let article of this.articlesA) {
		  	if(article.pin){
				this.Particles.push(article)
			}
		  }
		  this.Particles = this.Particles.reverse()
	  }
  },
  methods: {
	  articles(){
		  let that = this
		  this.$axios.post('articles',{}).then(function(results){
			  that.articlesA = results.data
			  that.tempArticle = that.articlesA.slice(0,10)
		  },function(err){
			  console.log(err)
		  })
	  },
	  goto(aid){
		  this.$router.push({ path: '/article/'+aid })
	  },
	  handleCurrentChange(val){
		  this.tempArticle = this.articlesA.slice(10*(val-1),10*val)
	  }
  },
  created(){
	  this.articles()
  }
}
</script>

<style scoped>
.article-all ul{
	display: flex;
	flex: auto;
	flex-wrap: wrap;
	list-style: none;
	justify-content: center;
	margin: 0 auto;
	width: 60%;
}
.article{
    position: relative;
    width: 30em;
    height: 20em;
    margin: 2em;
	flex: none;
    border-radius: 10px;
    transition: opacity .3s ease-in-out;
}
.pin{
	width: 80%;
	height: 32em;
}
.article-all-h2{
	margin: 2em 0 .5em 0;
	color: white;
	width: 100%;
	/* background-color: rgba(255,255,255,.5); */
	/* border-radius: 1em; */
	/* padding: .2em .7em; */
}
.article-all-h2:hover{
	color: pink;
}
.article a{
	width: 100%;
	height: 100%;
	text-decoration: none;
	color: white;
}
.article .bg-img{
	width: 100%;
	height: 100%;
	transition: all .3s ease-in-out;
	box-shadow: 5px 5px 5px rgba(0,0,0,0.2);
	filter: brightness(1) saturate(1) blur(0px);
}
.article .bg-img img{
	width: 100%;
	height: 100%;
	border-radius: 10px;
}
.article-content{
    position: absolute;
    top: 0;
    left: 0;
	right: 0;
	bottom: 0;
	text-align: start;
    width: 100%;
    height: 100%;
    color: white;
    transition: all .3s ease-in-out;
}
.article-content h2{
	position: absolute;
	/* top: 50%; */
	transform: translate(0, -5.5em);
	bottom: 0;
	right: 0;
	left: 0;
	padding-top: 0;
    text-align: center;
    transition: all .3s ease-in-out;
}
.pin .article-content h2{
	transform: translate(0, -9em);
}
.article-content .line{
    margin: 1em;
    transition: all .3s ease-in-out;
    opacity: 0;
	position: absolute;
	transform: translate(0,0);
	top: 50%;
	bottom: 0;
	left: 0;
	right: 0;
}
.article-content .line>p{
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.article:hover .article-content .line{
	/* top: 70%; */
	transform: translate(0,50%);
    opacity: 1;
}
.article:hover .article-content h2{
	/* top: 40%; */
	transform: translate(0, -8em);
}
.pin:hover .article-content h2{
	/* top: 40%; */
	transform: translate(0, -11em);
}
.article:hover .bg-img{
    filter: brightness(.8) saturate(1.5) blur(7px);
}
.article:hover .article-type{
    background-color: rgba(0,0,0,.1);
}
.article-content .data{
    margin-top: 1em;
}
.article-content .data p span,.article-type span{
    user-select: none;
    margin-right: 1em;
    vertical-align: middle;
}
.article-content .data p img,.article-type img{
    width: 1.5em;
    margin-right: .3em;
    vertical-align: middle;
}
.article-type{
    position: absolute;
    top: 2em;
    left: 1em;
    color: white;
    border-radius: 7px;
    background-color: rgba(0,0,0,.4);
    transition: all .3s ease-in-out;
}
.pin .article-type{
	left: 4em;
}
.pin .pin-img{
	position: absolute;
	top: 2em;
	left: 1em;
	width: 2em;
	box-shadow: 2px 2px 3px #ff2a2a;
	border-radius: 1em;
}
.el-pagination{
	margin: 3em 0;
}
.article-pager .el-pager li.active{
	background-color: #47b7e1;
}
.pin{
    /* width: 30em !important;
    height: 20em !important;
    margin: 2em auto !important;
	flex: unset; */
}
.el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li{
	background-color: var(--theme-background-color);
}
.darkApp .article .bg-img{
	filter: brightness(.5) saturate(1.5) blur(2px);
}
.darkApp .article:hover .bg-img{
	filter: brightness(.7) saturate(1.5) blur(2px);
}
@media only screen and (max-width: 1590px){
	.article{
		flex: auto;
		height: 30em;
	}
	.article-content h2{
		transform: translate(0, -9em);
	}
	.article:hover .article-content h2{
		transform: translate(0, -11em);
	}
}
@media only screen and (max-width: 1000px){
	.article-all ul{
		width: 75%;
	}
	
}
@media only screen and (max-width: 770px){
	.article-all ul{
		width: 100%;
	}
	.article{
		flex: auto;
		height: 18em;
		margin: 1em;
	}
	.article-content .line{
		opacity: 1;
		top: 70%;
	}
	.article .bg-img{
	    filter: brightness(.8) saturate(1.5) blur(2px);
	}
	.article .article-content h2{
		top: 40%;
	}
	.article-content .data{
		font-size: .9em;
	}
	.article-content .data p span, .article-type span{
		margin-right: .5em;
	}
	.article-content .data p img, .article-type img{
		margin-right: .15;
	}
	.article-content h2{
		transform: translate(0, 0);
	}
	.article:hover .article-content .line{
		transform: translate(0,0);
	}
	.article:hover .article-content h2{
		transform: translate(0, 0);
	}
	.pin .article-content h2{
		transform: translate(0, 0);
	}
	.pin:hover .article-content h2{
		transform: translate(0, 0);
	}
	
}
</style>
